import React from "react";
import { Link } from 'gatsby';
import configuration from './configuration';
import axios from 'axios';
import Magnifier from "react-magnifier";


const GetDoek = props => {
  return (
    <div className='doek' style={{border: '3px solid black'}}>
    <Magnifier
      src={configuration.doek_url + '?' + new Date().getTime()}
      alt="doek"
      mgShape="square"
      zoomFactor="4"
    />
    </div>
  )
}

const GetSoldCount = props => {
  return (
    <div className='sold'>
    <h4>{props.PixelsSold} pixels sold</h4>
    </div>
  )
}

class App extends React.Component {
  state = {
    PixelsSold: '-'
  }

  componentDidMount(){
    axios.get(configuration.token_sold_count).then((response) => {
      // console.log(response)
      this.setState({PixelsSold: response.data.number_sold})
    })
  }

  render() {
    return (
      <div style={{ maxWidth: `1200px`, marginBottom: `1.45rem` , 'marginLeft': '4px', 'marginRight': '4px'}} align="left">
          <GetSoldCount
            PixelsSold={this.state.PixelsSold}
          />
          <GetDoek/>
          <h4><Link to="/what_is_etherdoek">What is Etherdoek?</Link></h4>
      </div>
    );
  }
}

export default App;
