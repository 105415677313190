import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import App from "../components/App"

const IndexPage = () => (
    <Layout>
      <SEO title="Welcome" keywords={['Etherdoek']} />
        <App/>
    </Layout>
)

export default IndexPage
